<template>
  <div>
    <util-date-type-picker />
    <util-date-range-picker />

    <div class="row">
      <div class="col-xl-4">
        <div class="cui__utils__heading">
          <strong>Number of Cards</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="font-weight-bold font-size-70 text-primary text-center">{{calcTotalCards}}</div>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="cui__utils__heading">
          <strong>Average Cards per Day</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="font-weight-bold font-size-70 text-primary text-center">{{calcAverageCards}}</div>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="cui__utils__heading">
          <strong>Number of Participants</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="font-weight-bold font-size-70 text-primary text-center">{{calcTotalParticipants}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-xl-6">
        <div class="cui__utils__heading">
          <strong>Sender Profiles</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <chart-cards-sent-byprofiles />
          </div>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="cui__utils__heading">
          <strong>Sending Category</strong>
        </div>
        <div class="card">
          <div class="card-body">
            <chart-sending-category :chartHeight=203 />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateTypePicker from './DateTypePicker'
import DateRangePicker from './DateRangePickerHelper'
import CardsSentBySenderProfiles from './CardsSentBySenderProfiles'
import SendingCategoryChart from './SendingCategoryChart'
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'Summary',
  data: function () {
    const calcTotalCards = 0
    const calcAverageCards = 0
    const calcTotalParticipants = 0
    return {
      calcTotalCards,
      calcAverageCards,
      calcTotalParticipants,
    }
  },
  components: {
    'util-date-type-picker': DateTypePicker,
    'util-date-range-picker': DateRangePicker,
    'chart-cards-sent-byprofiles': CardsSentBySenderProfiles,
    'chart-sending-category': SendingCategoryChart,
  },

  computed: {
    ...mapState('admin', [
      'orderedProductsDetails',
      'globalDateRange',
      'globalClientSelected',
      'globalDateTypeSelected',
    ]),
  },

  methods: {
    ...mapActions('admin', [
      'fetchOrderedProductDetails',
    ]),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading',
    ]),

    triggerUpdateChart() {
      this.startLoading()
      const selectFilter = '(count,total,hits.(data.(creation_date,c_senderProfile,' +
          'customer_info.(customer_no),product_items.(product_id,quantity,c_productCategory))))'

      this.fetchOrderedProductDetails({
        saveToStateObj: 'setOrderedProductsDetails',
        selectFilter: selectFilter,
        dateType: this.globalDateTypeSelected,
        fromDate: this.globalDateRange.fromDate,
        toDate: this.globalDateRange.toDate,
      })
        .then(() => this.calculateValues(this.orderedProductsDetails, this.globalDateRange))
        .then(() => this.stopLoading())
      // Using Watch so commenting
      /*  .then(() => {
          this.$children[1].triggerUpdateChart()
          this.$children[2].triggerUpdateChart()
          this.$children[3].triggerUpdateChart()
        })  */
    },

    calculateValues(orderDetails, dateRange) {
      const groupCardsByUser = []
      const orders = orderDetails || []
      let totCards = 0

      // Calculate Values - Total Cards, Average Cards, No of Participants
      orders.reduce((res, value) => {
        const custNo = value.data.customer_info.customer_no
        const prodItems = value.data.product_items || []
        prodItems.forEach((prodItem) => {
          totCards += 1
          if (!res[custNo]) {
            res[custNo] = { userId: custNo }
            groupCardsByUser.push(res[custNo])
          }
        })
        return res
      }, {})

      const noOfDays = (dateRange.toDate).diff(dateRange.fromDate, 'days')
      this.calcTotalCards = totCards
      this.calcAverageCards = (totCards / noOfDays).toFixed(1)
      this.calcTotalParticipants = groupCardsByUser.length
    }
  },

  mounted() {
    this.triggerUpdateChart()
  },

  watch: {
    globalClientSelected() {
      this.triggerUpdateChart()
    }
  },
}
</script>
