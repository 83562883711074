<template>
  <div>
    <div class="position-relative">
      <vue-chartist
        class="height-300 ct-hidden-points"
        type="Bar"
        :data="data"
        :options="options"
      />
    </div>
  </div>
</template>

<script>
import data from './data.json'
import VueChartist from 'v-chartist'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import { mapState } from 'vuex'
export default {
  name: 'CardsSentBySenderProfiles',
  components: {
    'vue-chartist': VueChartist,
  },
  data: function () {
    const options = {
      fullWidth: true,
      showLabel: true,
      seriesBarDistance: 10,
      horizontalBars: true,
      reverseData: true,
      axisX: {
        showGrid: true,
        showLabel: true,
        offset: 15,
        onlyInteger: true,
      },
      axisY: {
        showGrid: false,
        showLabel: true,
        offset: 150,
      },
      chartPadding: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0,
      },
      low: 0,
      plugins: [ChartistTooltip({ anchorToPoint: true, appendToBody: true, seriesName: true })],
    }

    return {
      data,
      options,
    }
  },

  computed: {
    ...mapState('admin', [
      'orderedProductsDetails',
    ]),
  },

  methods: {
    triggerUpdateChart() {
      this.updateChart(this.processSummaryReport(this.orderedProductsDetails))
    },

    processSummaryReport(summaryReport) {
      const groupOrdersBySenderProfiles = []
      const orders = summaryReport || []

      // Group orders by date
      orders.reduce((res, value) => {
        const senderProfile = value.data.c_senderProfile
        const prodItems = value.data.product_items || []
        prodItems.forEach((prodItem) => {
          if (!res[senderProfile]) {
            res[senderProfile] = { sProfile: senderProfile, qty: 0 }
            groupOrdersBySenderProfiles.push(res[senderProfile])
          }
          res[senderProfile].qty += prodItem.quantity
        })
        return res
      }, {})

      // Sorting based on quantiy
      groupOrdersBySenderProfiles.sort((a, b) => (b.qty > a.qty) ? 1 : -1)

      // Displaying only top 8 categories
      groupOrdersBySenderProfiles.length = 8

      return groupOrdersBySenderProfiles
    },

    updateChart(groupOrdersBySenderProfiles) {
      const senderProfile = []
      const orderQty = []
      groupOrdersBySenderProfiles.forEach(order => {
        senderProfile.push(order.sProfile)
        orderQty.push(order.qty)
      })

      this.data = {
        series: [orderQty],
        labels: senderProfile,
      }
    },
  },

  watch: {
    orderedProductsDetails() {
      this.triggerUpdateChart()
    }
  },
}
</script>
